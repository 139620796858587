import React from 'react'
import Box from '@mui/material/Box'
import PlayerHeadshotAvatar from '../boards/PositionalBoard/PlayerHeadshotAvatar'

type PostHeadshotProps = {
    tag: DTO.PlayerTag | null | undefined
    marginRight?: number
}

export const PostHeadshot = ({ tag, marginRight = 1 }: PostHeadshotProps): JSX.Element =>
    tag ? (
        <Box sx={{ float: 'left', display: 'inline-block', marginRight }}>
            <PlayerHeadshotAvatar
                outlined
                imageUrl={tag.playerHeadshot}
                teamImageUrl={tag.teamLogoUrl}
                height={35}
                width={35}
                toolTip={`${tag.name ? `${tag.name}` : ''}${tag.teamName ? ` - ${tag.teamName}` : ''}`}
            />
        </Box>
    ) : (
        <Box />
    )
