import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import PlaceIcon from '@mui/icons-material/Place'
import { useBreakPoints } from '../../../lib/hooks'

const locationIconStyles = { width: 30, height: 30, color: 'rgba(0, 0, 0, 0.6)' }

type LocationProps = {
    event: string | null
    location: string | null
}

const Location = ({ event, location }: LocationProps): JSX.Element => {
    const { isMobile } = useBreakPoints()

    return (
        <Stack direction={isMobile ? 'column' : 'row'} sx={{ flexWrap: 'wrap', gap: isMobile ? 0 : 6, marginTop: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <PlaceIcon style={locationIconStyles} />
                <Stack>
                    {event && (
                        <Typography variant="body2" fontWeight="medium">
                            {event}
                        </Typography>
                    )}
                    {location && (
                        <Typography variant="body2" fontWeight="medium" color="text.secondary">
                            {location}
                        </Typography>
                    )}
                </Stack>
            </Box>
        </Stack>
    )
}

export default Location
