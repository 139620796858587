import Box from '@mui/material/Box'
import React from 'react'
import PushPinIcon from '@mui/icons-material/PushPin'

const PinPostIcon = (): JSX.Element => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
        }}
    >
        <PushPinIcon
            color="error"
            sx={{
                transform: 'rotate(45deg)',
            }}
        />
    </Box>
)

export default PinPostIcon
