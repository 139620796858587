import * as React from 'react'
import { useSession } from 'next-auth/react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Collapse from '@mui/material/Collapse'
import CardContent from '@mui/material/CardContent'
import dayjs from 'dayjs'
import capitalize from '@mui/utils/capitalize'
import {
    isIntelReport,
    isScoutingPost,
    isPlayerDevelopmentPost,
    isCommunityPost,
    isGamePost,
    isWorkoutPost,
} from '../../lib/types/postTypes'
import { useEditPost, useQueryAttachments } from '../../lib/hooks'
import ConfirmationDialog from '../dialog/ConfirmationDialog'
import MenuItemLink from '../MenuItemLink'
import Controls from '../Controls'
import { renderAttachment } from '../form/AttachFiles/AttachmentList'
import IntelPost from './Intel/IntelPost'
import ScoutingPost from './Scouting/ScoutingPost'
import CommentForm from './CommentForm'
import Comment from './Comment'
import PlayerDevelopmentPost from './PlayerDevelopment/PlayerDevelopmentPost'
import CommunityPost from './Community/CommunityPost'
import GamePost from './Game/GamePost'
import WorkoutPost from './Workout/WorkoutPost'
import { isAfter, isBefore } from '@/lib/utils/formatters'

export type PostProps<T> = {
    post: T
    editPostSection: React.ReactElement
    commentSection: React.ReactElement | null
    expanded: boolean
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>
    mobileTagLimit: number
    tabletTagLimit: number
    desktopTagLimit: number
    maxCardContentHeight: number
    canEditPost: boolean
    isPinned: boolean
}

type PostContainerProps = {
    post: DTO.Post
    expandOnMount?: boolean
}

const mobileTagLimit = 2
const tabletTagLimit = 2
const desktopTagLimit = 4
const maxCardContentHeight = 330

const Post = ({ post, expandOnMount }: PostContainerProps): JSX.Element => {
    const [expanded, setExpanded] = React.useState(expandOnMount || false)
    const { data: session } = useSession()
    const { mutateAsync } = useEditPost<DTO.Post>()
    const { data: signedAttachments } = useQueryAttachments(
        {
            postId: post.postID,
        },
        { enabled: !!post.attachments.length }
    )

    const canEditPost = !!(session?.roles.featurePermissions['post-management'] || post.author.isLoggedInUser)
    const playerTag = post.tags.find((tag) => tag.entityType === 'player')
    const isPinned = post.pinStart !== null && post.pinEnd !== null && isAfter(post.pinStart) && isBefore(post.pinEnd)

    const CommentSection =
        post.status === 'ACTIVE' ? (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <>
                    {!!(signedAttachments?.length || post.comments.length) && (
                        <Divider sx={{ display: expanded ? 'block' : 'none' }} />
                    )}
                    {!!signedAttachments?.length && (
                        <CardContent sx={{ paddingTop: 0, paddingBottom: 1 }}>
                            <Typography variant="overline" fontWeight="medium">
                                Attachments
                            </Typography>
                            {signedAttachments.map((a) => (
                                <div key={a.attachmentId}>{renderAttachment(a)}</div>
                            ))}
                        </CardContent>
                    )}
                    {!!post.comments.length && (
                        <>
                            {!!signedAttachments?.length && <Divider sx={{ display: expanded ? 'block' : 'none' }} />}
                            <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Typography variant="overline" fontWeight="medium">
                                    Comments
                                </Typography>
                            </CardContent>
                            <Divider sx={{ display: expanded ? 'block' : 'none' }} />
                            <CardContent
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                            >
                                {post.comments
                                    .sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? 1 : -1))
                                    .map((comment, i) => (
                                        <React.Fragment key={comment.commentId}>
                                            <Comment comment={comment} />
                                            {i !== post.comments.length - 1 && <Divider />}
                                        </React.Fragment>
                                    ))}
                            </CardContent>
                        </>
                    )}
                    <Divider />
                    <CardContent
                        sx={{
                            displayPrint: 'none',
                            paddingTop: 0,
                            '&.MuiCardContent-root:last-child': { paddingBottom: 0 },
                        }}
                    >
                        <CommentForm initialValues={{ postId: post.postID, formattedText: '' }} />
                    </CardContent>
                </>
            </Collapse>
        ) : null

    const EditPostSection = (
        <Controls>
            <MenuItemLink
                key={`/posts/${post.postID}/edit${
                    isScoutingPost(post) && playerTag ? `?playerid=${playerTag.entitySlug}` : ''
                }`}
                href={`/posts/${post.postID}/edit${
                    isScoutingPost(post) && playerTag ? `?playerid=${playerTag.entitySlug}` : ''
                }`}
                label="Edit"
                authorize={canEditPost}
                icon={<EditIcon style={{ color: 'grey', fontSize: 'inherit' }} />}
            />
            <ConfirmationDialog
                trigger={
                    <MenuItem sx={{ gap: 1 }}>
                        <DeleteIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                        Delete
                    </MenuItem>
                }
                headerText={`Delete ${capitalize(post.type.toLocaleLowerCase())} Report?`}
                confirmText="Delete"
                handleConfirm={() => mutateAsync({ ...post, status: 'DELETED' })}
            />
        </Controls>
    )

    if (isIntelReport(post)) {
        return (
            <IntelPost
                post={post}
                editPostSection={EditPostSection}
                commentSection={CommentSection}
                expanded={expanded}
                setExpanded={setExpanded}
                mobileTagLimit={mobileTagLimit}
                tabletTagLimit={tabletTagLimit}
                desktopTagLimit={desktopTagLimit}
                maxCardContentHeight={maxCardContentHeight}
                canEditPost={canEditPost}
                isPinned={isPinned}
            />
        )
    }
    if (isScoutingPost(post)) {
        return (
            <ScoutingPost
                post={post}
                editPostSection={EditPostSection}
                commentSection={CommentSection}
                expanded={expanded}
                setExpanded={setExpanded}
                mobileTagLimit={mobileTagLimit}
                tabletTagLimit={tabletTagLimit}
                desktopTagLimit={desktopTagLimit}
                maxCardContentHeight={maxCardContentHeight}
                canEditPost={canEditPost}
                isPinned={isPinned}
            />
        )
    }
    if (isPlayerDevelopmentPost(post)) {
        return (
            <PlayerDevelopmentPost
                post={post}
                editPostSection={EditPostSection}
                commentSection={CommentSection}
                expanded={expanded}
                setExpanded={setExpanded}
                mobileTagLimit={mobileTagLimit}
                tabletTagLimit={tabletTagLimit}
                desktopTagLimit={desktopTagLimit}
                maxCardContentHeight={maxCardContentHeight}
                canEditPost={canEditPost}
                isPinned={isPinned}
            />
        )
    }
    if (isCommunityPost(post)) {
        return (
            <CommunityPost
                post={post}
                editPostSection={EditPostSection}
                commentSection={CommentSection}
                expanded={expanded}
                setExpanded={setExpanded}
                mobileTagLimit={mobileTagLimit}
                tabletTagLimit={tabletTagLimit}
                desktopTagLimit={desktopTagLimit}
                maxCardContentHeight={maxCardContentHeight}
                canEditPost={canEditPost}
                isPinned={isPinned}
            />
        )
    }
    if (isGamePost(post)) {
        return (
            <GamePost
                post={post}
                teamLogoUrl={post.team?.url}
                editPostSection={EditPostSection}
                commentSection={CommentSection}
                expanded={expanded}
                setExpanded={setExpanded}
                mobileTagLimit={mobileTagLimit}
                tabletTagLimit={tabletTagLimit}
                desktopTagLimit={desktopTagLimit}
                maxCardContentHeight={maxCardContentHeight}
                canEditPost={canEditPost}
                isPinned={isPinned}
            />
        )
    }
    if (isWorkoutPost(post)) {
        return (
            <WorkoutPost
                post={post}
                editPostSection={EditPostSection}
                commentSection={CommentSection}
                expanded={expanded}
                setExpanded={setExpanded}
                mobileTagLimit={mobileTagLimit}
                tabletTagLimit={tabletTagLimit}
                desktopTagLimit={desktopTagLimit}
                maxCardContentHeight={maxCardContentHeight}
                canEditPost={canEditPost}
                isPinned={isPinned}
            />
        )
    }
    return <div />
}

export default Post
