import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import EmailIcon from '@mui/icons-material/Email'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PersonIcon from '@mui/icons-material/Person'
import PeopleIcon from '@mui/icons-material/People'
import { useBreakPoints } from '../../../lib/hooks'
import InterviewIcon from '../../icon/InterviewIcon'

const settingIconStyles = { width: 30, height: 30, color: 'rgba(0, 0, 0, 0.6)' }

type SettingProps = { text: Enum.IntelReportSettingMedium | string; icon: JSX.Element }
const settingProperties: Record<Enum.IntelReportSettingMedium, SettingProps> = {
    Phone: { text: 'Phone Call', icon: <PhoneInTalkIcon sx={settingIconStyles} /> },
    Text: { text: 'Text', icon: <QuestionAnswerIcon sx={settingIconStyles} /> },
    'In-Person': { text: 'In Person', icon: <PeopleIcon sx={settingIconStyles} /> },
    Email: { text: 'Email', icon: <EmailIcon sx={settingIconStyles} /> },
    Interview: { text: 'Interview', icon: <InterviewIcon style={settingIconStyles} /> },
    Media: { text: 'Media', icon: <NewspaperIcon style={settingIconStyles} /> },
}

type SourceProps = {
    sourcePerson: Pick<DTO.Entity, 'entityId' | 'name' | 'type' | 'urlSlug'> | null
    sourcePersonDescription: string | null
    sourceOrganization: DTO.CreateIntelReport['sourceOrganization']
    sourceOrganizationDescription: string | null
    settingDirection: DTO.CreateIntelReport['settingDirection']
    settingMedium: DTO.CreateIntelReport['settingMedium']
    reliabilitySources: DTO.CreateIntelReport['reliabilitySources']
    reliabilityInformation: DTO.CreateIntelReport['reliabilityInformation']
    isWidget?: boolean
}

const Source = ({
    sourcePerson,
    sourcePersonDescription,
    sourceOrganizationDescription,
    sourceOrganization,
    settingDirection,
    settingMedium,
    reliabilitySources,
    reliabilityInformation,
    isWidget = false,
}: SourceProps): JSX.Element => {
    const { isMobile } = useBreakPoints()
    const settingIconText =
        settingMedium && (settingProperties as Record<string, SettingProps | undefined>)[settingMedium]
    return (
        <Stack
            direction={isMobile || isWidget ? 'column' : 'row'}
            sx={{ flexWrap: 'wrap', gap: isMobile || isWidget ? 0 : 6, marginTop: 2 }}
        >
            {sourcePersonDescription && (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <PersonIcon sx={{ width: 30, height: 30, color: 'text.secondary' }} />
                    <Stack>
                        <Stack direction="row" spacing={0.5}>
                            <Typography variant="body2" fontWeight="medium">
                                {sourcePerson ? sourcePerson.name : sourcePersonDescription}
                            </Typography>

                            <Typography variant="body2" fontWeight="medium" color="text.secondary">
                                ({reliabilitySources})
                            </Typography>
                        </Stack>
                        <Typography variant="body2" fontWeight="medium" color="text.secondary">
                            {sourceOrganization ? sourceOrganization.name : sourceOrganizationDescription}
                        </Typography>
                    </Stack>
                </Box>
            )}

            {settingIconText && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1,
                        marginTop: isWidget || isMobile ? 2 : undefined,
                    }}
                >
                    {settingIconText.icon}
                    <Stack>
                        <Stack direction="row" alignItems="baseline" spacing={1}>
                            <Typography display="flex" variant="body2" fontWeight="medium" sx={{ gap: 0.5 }}>
                                {settingDirection !== 'Not Applicable' && settingDirection} {settingIconText.text}
                                {settingMedium === 'In-Person' && settingDirection !== 'Not Applicable' && (
                                    <Typography variant="body2" fontWeight="medium" color="text.secondary">
                                        (Initiated by {settingDirection === 'Incoming' ? 'source' : 'us'})
                                    </Typography>
                                )}
                            </Typography>
                        </Stack>
                        {reliabilityInformation && (
                            <Stack direction="row" alignItems="baseline" spacing={0.5}>
                                <Typography
                                    variant="overline"
                                    lineHeight="inherit"
                                    fontWeight="medium"
                                    color="text.secondary"
                                >
                                    Info. Reliability:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium">
                                    {reliabilityInformation}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            )}
        </Stack>
    )
}

export default Source
