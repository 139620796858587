import type { PostProps } from '../Post'
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import PublicIcon from '@mui/icons-material/Public'
import { useBreakPoints } from '../../../lib/hooks'
import { handleTouchTap } from '../../form/formUtils'
import FormattedText from '../FormattedText'
import UncollapsedCardContent from '../UncollapsedCardContent'
import CardActions from '../CardActions'
import PostTags from '../PostTags'
import { PostHeadshot } from '../PostHeadshot'
import PinPostIcon from '../PinPostIcon'
import Location from './Location'

const CommunityPost = ({
    post,
    editPostSection,
    commentSection,
    expanded,
    setExpanded,
    mobileTagLimit,
    tabletTagLimit,
    desktopTagLimit,
    maxCardContentHeight,
    canEditPost,
    isPinned = false,
}: PostProps<DTO.CommunityReport>): JSX.Element => {
    const [hasOverflowContent, setHasOverflowContent] = useState(false)
    const { isMobile, isTablet } = useBreakPoints()
    const { tags, subject, community, communityTagName, event, location } = post

    const postTextTypes = [{ label: 'Community', text: community }].filter(({ text }) => !!text)

    return (
        <Card
            sx={{ pageBreakInside: expanded ? 'auto' : 'avoid' }}
            id={`card-element-${post.postID}`}
            variant="outlined"
        >
            <CardHeader
                sx={{
                    paddingTop: 1,
                    paddingBottom: 0,
                    '.MuiCardHeader-avatar': { xs: { marginRight: 0 }, sm: { marginRight: '16px' } },
                }}
                avatar={
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            label={isMobile ? '' : `${'COMMUNITY'}`}
                            sx={{
                                color: 'common.white',
                                backgroundColor: 'post.community',
                                fontWeight: 'medium',
                                display: 'flex',
                                justifyContent: 'center',
                                '.MuiChip-label': { xs: { paddingLeft: 0 }, sm: { paddingLeft: '8px' } },
                            }}
                            avatar={<PublicIcon style={{ position: 'relative', color: 'white' }} />}
                        />

                        <Stack direction="row" spacing={1}>
                            <PostTags
                                tags={tags}
                                startIndex={0}
                                endIndex={isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit}
                                expanded={expanded}
                                abbreviate={isMobile || isTablet}
                            />
                            <Chip size="small" color="default" variant="outlined" label={communityTagName} />
                        </Stack>
                    </Stack>
                }
                action={canEditPost && editPostSection}
                subheader={isPinned && <PinPostIcon />}
            />
            <Divider sx={{ paddingTop: 1 }} />
            <UncollapsedCardContent
                maxCardContentHeight={maxCardContentHeight}
                expanded={expanded}
                hasOverflowContent={hasOverflowContent}
                setHasOverflowContent={setHasOverflowContent}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 1,
                        marginBottom: 1,
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <PostHeadshot tag={tags[0] as DTO.PlayerTag} />
                        <Typography
                            variant="h6"
                            fontSize={isMobile ? '18px' : '20px'}
                            lineHeight={isMobile ? 1.4 : 'initial'}
                            sx={{ marginTop: '5px' }}
                        >
                            {subject}
                        </Typography>
                    </Box>
                </Box>
                <Box onTouchEnd={(e) => handleTouchTap(e, setExpanded, expanded)}>
                    <Box marginBottom={2}>{(event || location) && <Location event={event} location={location} />}</Box>
                    <FormattedText text={postTextTypes[0]?.text || ''} marginBottom={false} />
                </Box>
            </UncollapsedCardContent>
            <CardActions
                post={post}
                expanded={expanded}
                setExpanded={setExpanded}
                isMobile={isMobile}
                hasCollapsedContent={hasOverflowContent || postTextTypes.length > 1}
            />
            {commentSection}
        </Card>
    )
}

export default CommunityPost
