import type { CardContentProps } from '@mui/material/CardContent'
import React, { useRef, useEffect } from 'react'
import CardContent from '@mui/material/CardContent'

type UncollapsedCardContentProps = {
    maxCardContentHeight: number | undefined
    expanded: boolean
    hasOverflowContent: boolean
    setHasOverflowContent?: React.Dispatch<React.SetStateAction<boolean>>
    children: React.ReactNode | React.ReactNode[]
    padding?: number
    sx?: CardContentProps['sx']
    maskImagePercent?: string
}

const UncollapsedCardContent = ({
    children,
    maxCardContentHeight,
    expanded,
    hasOverflowContent,
    setHasOverflowContent,
    padding,
    sx,
    maskImagePercent = '90%',
}: UncollapsedCardContentProps): JSX.Element => {
    const cardContentRef = useRef<HTMLDivElement | null>(null)

    const maxHeight = expanded ? undefined : maxCardContentHeight
    useEffect(() => {
        if (!maxHeight || !cardContentRef.current) {
            setHasOverflowContent?.(false)
        } else {
            setHasOverflowContent?.(cardContentRef.current.scrollHeight > maxHeight + 8)
        }
    }, [maxHeight, setHasOverflowContent])

    return (
        <CardContent
            ref={cardContentRef}
            sx={{
                paddingTop: padding !== undefined ? padding : 1.5,
                paddingBottom: padding !== undefined ? padding : 1,
                paddingLeft: padding !== undefined ? padding : undefined,
                paddingRight: padding !== undefined ? padding : undefined,
                maxHeight,
                overflow: maxHeight ? 'hidden' : undefined,
                maskImage:
                    maxHeight && hasOverflowContent
                        ? `linear-gradient(180deg, #000 ${maskImagePercent}, transparent)`
                        : undefined,
                ...sx,
            }}
        >
            {children}
        </CardContent>
    )
}

export default UncollapsedCardContent
