import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import GroupsIcon from '@mui/icons-material/Groups'
import Popover from '@mui/material/Popover'

type AudienceProps = {
    audience: { id: number; slug: string; firstName: string; lastName: string }[]
}

const Audience = ({ audience }: AudienceProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <Box sx={{ position: 'relative', top: '6px' }}>
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <GroupsIcon sx={{ color: 'text.secondary', cursor: 'pointer' }} />
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography variant="body2" fontWeight="medium" color="text.secondary" sx={{ p: 1, maxWidth: 250 }}>
                    {audience.map((a) => `${a.firstName[0]}. ${a.lastName}`).join(', ')}
                </Typography>
            </Popover>
        </Box>
    )
}
export default Audience
