import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import VideocamIcon from '@mui/icons-material/Videocam'
import SportsIcon from '@mui/icons-material/Sports'
import StadiumIcon from '@mui/icons-material/Stadium'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import { formatDateString } from '../../../lib/utils/formatters'

type SettingProps = DTO.ScoutingSettings

const iconStyles = { color: 'rgba(0, 0, 0, 0.6)', position: 'relative' }

const settingProperties: {
    [key in Enum.ScoutingReportSettingType]?: {
        icon: JSX.Element | null
    }
} = {
    Game: { icon: <StadiumIcon sx={{ ...iconStyles }} /> },
    Practice: { icon: <SportsIcon sx={{ ...iconStyles }} /> },
    Workout: { icon: <SportsIcon sx={{ ...iconStyles }} /> },
    'Event/Camp': { icon: <SportsIcon sx={{ ...iconStyles }} /> },
    Video: { icon: <VideocamIcon sx={{ ...iconStyles }} /> },
    Data: { icon: <VideoFileIcon sx={{ ...iconStyles }} /> },
    Other: { icon: null },
}
const Setting = ({ type, date, ...settings }: SettingProps): JSX.Element | null =>
    type && (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0.5,
                marginLeft: 1,
                position: 'relative',
            }}
        >
            {!!settingProperties[type] && settingProperties[type]?.icon}
            <Stack direction="row" color="text.secondary" spacing={1}>
                {settings.awayTeam && settings.homeTeam && (
                    <Typography variant="body2" fontWeight="medium">
                        {type === 'Game' ? `${settings.awayTeam || ''} @ ${settings.homeTeam || ''}` : type}
                    </Typography>
                )}
                {type === 'Event/Camp' && settings.scoutingEvent && (
                    <Typography variant="body2" fontWeight="medium">
                        {settings.scoutingEvent}
                    </Typography>
                )}
                {date && <Typography variant="body2">{formatDateString(date, 'M/DD/YY', 'utc')}</Typography>}
            </Stack>
        </Box>
    )

export default Setting
