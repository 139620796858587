import { useSession } from 'next-auth/react'
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSearchParams } from 'next/navigation'
import { useBreakPoints, useDeleteComment } from '../../lib/hooks'
import ConfirmationDialog from '../dialog/ConfirmationDialog'
import Controls from '../Controls'
import CopyLink from '../CopyLink'
import DateTime from './DateTime'
import Author from './Author'
import FormttedText from './FormattedText'
import CommentForm from './CommentForm'

type CommentProps = {
    comment: DTO.Comment
}

export type ConfirmationDialogContentProps = {
    open: boolean
    onClose: () => void
    handleDelete: () => void
}

const Comment = ({ comment }: CommentProps): JSX.Element => {
    const searchParams = useSearchParams()
    const [isEditing, setIsEditing] = useState(false)
    const { formattedText, author, createdAt } = comment
    const { isMobile } = useBreakPoints()
    const { mutateAsync } = useDeleteComment()
    const { data: session } = useSession()
    const canEditComment =
        !!session?.roles.featurePermissions['comment-management'] || session?.userId === comment.editorId

    const scrollToComment = (commentId: string): void => {
        const field = document.getElementById(commentId)
        setTimeout(
            () =>
                field?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start',
                }),
            0
        )
    }

    useEffect(() => {
        if (searchParams?.get('commentId')) scrollToComment(searchParams.get('commentId') as string)
    }, [searchParams])

    if (isEditing) {
        return (
            <CommentForm
                initialValues={{
                    commentId: comment.commentId,
                    postId: comment.postId,
                    formattedText: comment.formattedText,
                }}
                setIsEditing={setIsEditing}
            />
        )
    }

    return (
        <Box
            id={comment.commentId}
            paddingTop={1}
            sx={searchParams?.get('commentId') === comment.commentId ? { backgroundColor: '#eef7fd' } : null}
        >
            <Stack direction="row" alignItems="flex-start">
                <FormttedText text={formattedText} />
                {canEditComment && (
                    <Controls>
                        <MenuItem onClick={() => setIsEditing(true)} sx={{ gap: 1 }}>
                            <EditIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                            Edit
                        </MenuItem>
                        <ConfirmationDialog
                            trigger={
                                <MenuItem sx={{ gap: 1 }}>
                                    <DeleteIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                                    Delete
                                </MenuItem>
                            }
                            headerText="Delete Comment?"
                            confirmText="Delete"
                            handleConfirm={() => mutateAsync(comment)}
                        />
                    </Controls>
                )}
            </Stack>
            <Stack direction="row" spacing={2} alignItems="baseline">
                <Author isMobile={isMobile} name={author.name} nameAbbr={author.nameAbbr} urlSlug={author.urlSlug} />
                <DateTime isMobile={isMobile} postedAt={createdAt} />
                <Box sx={{ position: 'relative', bottom: 1 }}>
                    <CopyLink
                        text={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/posts/${comment.postId}?commentId=${comment.commentId}`}
                    />
                </Box>
            </Stack>
        </Box>
    )
}

export default Comment
