import React from 'react'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const NotKnicksQuality = ({ isTablet }: { isTablet: boolean }): JSX.Element => (
    <Stack
        sx={{
            display: 'block',
            width: { xs: '100%', sm: 'initial' },
        }}
    >
        <Chip
            sx={{ paddingX: 0.5 }}
            size="small"
            icon={<WarningAmberIcon sx={{ width: 16, height: 16 }} />}
            label={isTablet ? 'NKQ' : 'Not Knicks Quality'}
            variant="outlined"
            color="error"
        />
    </Stack>
)

export default NotKnicksQuality
