import React from 'react'
import Typography from '@mui/material/Typography'
import { formatDateString } from '../../lib/utils/formatters'

type DateTimeProps = { isMobile: boolean; postedAt: string }

const DateTime = ({ isMobile, postedAt }: DateTimeProps): JSX.Element => (
    <Typography variant="caption" fontSize={14} color="text.secondary">
        {isMobile
            ? formatDateString(postedAt, 'M/DD/YY', 'local')
            : formatDateString(postedAt, 'M/DD/YY h:mm A', 'local')}
    </Typography>
)

export default DateTime
