import type { PostProps } from '../Post'
import React, { useState } from 'react'
import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import { ascending } from 'd3-array'
import InfoIcon from '@mui/icons-material/Info'
import { red } from '@mui/material/colors'
import { keyframes } from '@mui/system'
import dayjs from 'dayjs'
import PostTags from '../PostTags'
import UncollapsedCardContent from '../UncollapsedCardContent'
import CardActions from '../CardActions'
import FormattedText, { SectionHeader } from '../FormattedText'
import PinPostIcon from '../PinPostIcon'
import WorkoutParticipant from './WorkoutParticipant'
import ParticipantMeasurments from './WorkoutParticipantMeasurements'
import { useBreakPoints, useGetKnicks } from '@/lib/hooks'
import { addDate, formatDateString, isAfter, isBefore, isToday } from '@/lib/utils/formatters'
import SummaryStat from '@/components/SummaryStat'
import { asNumber } from '@/lib/utils/math'
import Link from '@/components/Link'
import TeamLogo from '@/components/stats/StatsTeamLogo'
import PermissionContent from '@/components/wrappers/PermissionContent'
import { handleTouchTap } from '@/components/form/formUtils'

const WorkoutPost = ({
    post,
    editPostSection,
    commentSection,
    expanded,
    setExpanded,
    mobileTagLimit,
    tabletTagLimit,
    desktopTagLimit,
    maxCardContentHeight,
    canEditPost,
    isPinned = false,
}: PostProps<DTO.WorkoutReport>): JSX.Element => {
    const [hasOverflowContent, setHasOverflowContent] = useState(false)
    const { isMobile, isTablet } = useBreakPoints()
    const { tags, participants, startTime, endTime, date, notes } = post
    let status: Enum.WorkoutStatus = 'UPCOMING'
    if (startTime && endTime) {
        status =
            isAfter(addDate(startTime, -1, 'hour')) && isBefore(endTime)
                ? 'LIVE'
                : isAfter(endTime)
                ? 'COMPLETE'
                : 'UPCOMING'
    } else {
        status = isAfter(date) && !isToday(date) ? 'COMPLETE' : 'UPCOMING'
    }
    const { data: team } = useGetKnicks()
    const pulse = keyframes`
    to {
        box-shadow: 0 0 4px 2px ${red[400]};
    }
`

    return (
        <Card variant="outlined">
            <CardHeader
                sx={{
                    paddingTop: 1,
                    paddingBottom: 0,
                    '.MuiCardHeader-avatar': { xs: { marginRight: 0 }, sm: { marginRight: '16px' } },
                }}
                avatar={
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            label={isMobile ? undefined : 'WORKOUT'}
                            sx={(theme) => ({
                                color: 'common.white',
                                backgroundColor: theme.palette.knicks.blue,
                                fontWeight: 'medium',
                                display: 'flex',
                                justifyContent: 'center',
                                '.MuiChip-label': {
                                    xs: { paddingLeft: 0, display: 'none' },
                                    sm: { paddingLeft: '2px', display: 'block' },
                                },
                            })}
                            avatar={<TeamLogo url={team?.url} height={24} width={24} name={null} />}
                        />

                        <Stack direction="row" spacing={1}>
                            <PostTags
                                tags={tags}
                                startIndex={0}
                                endIndex={isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit}
                                expanded={expanded}
                                abbreviate={isMobile || isTablet}
                            />
                        </Stack>
                    </Stack>
                }
                action={canEditPost && editPostSection}
                subheader={isPinned && <PinPostIcon />}
            />
            {expanded && (
                <Stack direction="row" sx={{ paddingX: 2, position: 'relative', flexWrap: 'wrap', gap: 1 }}>
                    <PostTags
                        tags={tags}
                        startIndex={isMobile ? mobileTagLimit : desktopTagLimit}
                        endIndex={tags.length}
                        expanded={expanded}
                        abbreviate={isMobile || isTablet}
                    />
                </Stack>
            )}
            <Divider sx={{ paddingTop: 1 }} />
            <UncollapsedCardContent
                maxCardContentHeight={maxCardContentHeight}
                expanded={expanded}
                hasOverflowContent={hasOverflowContent}
                setHasOverflowContent={setHasOverflowContent}
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        onTouchEnd={(e) => (status === 'LIVE' ? undefined : handleTouchTap(e, setExpanded, expanded))}
                    >
                        <Stack>
                            <SectionHeader label="Details" />
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography
                                    variant="overline"
                                    lineHeight="inherit"
                                    fontWeight="medium"
                                    color="text.secondary"
                                    minWidth={65}
                                >
                                    Date/Time:
                                </Typography>
                                <Typography variant="body2" fontWeight="medium" whiteSpace="nowrap">
                                    {startTime
                                        ? formatDateString(startTime, 'ddd, MMM D, h:mm A z', 'eastern')
                                        : formatDateString(date, 'ddd, MMM D', 'eastern')}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography
                                    variant="overline"
                                    lineHeight="inherit"
                                    fontWeight="medium"
                                    color="text.secondary"
                                    minWidth={65}
                                >
                                    Status:
                                </Typography>
                                <Badge
                                    variant="dot"
                                    color={status === 'LIVE' ? 'error' : status === 'COMPLETE' ? 'success' : 'primary'}
                                    sx={{
                                        '.MuiBadge-dot': {
                                            animation:
                                                status === 'LIVE'
                                                    ? `${pulse} 1.75s ease infinite alternate-reverse`
                                                    : undefined,
                                        },
                                        left: '4px',
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    fontWeight="medium"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    left="4px"
                                    position="relative"
                                >
                                    {status === 'LIVE' ? 'Live' : status === 'COMPLETE' ? 'Completed' : 'Upcoming'}
                                </Typography>
                            </Stack>
                            {(status === 'LIVE' || status === 'UPCOMING') && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography
                                        variant="overline"
                                        lineHeight="inherit"
                                        fontWeight="medium"
                                        color="text.secondary"
                                        minWidth={65}
                                        sx={{ display: 'flex', gap: 0.5 }}
                                    >
                                        Watch:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 20,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                gap: 0,
                                                fontSize: 14,
                                                opacity: status === 'UPCOMING' ? 0.5 : 1,
                                                cursor: status === 'UPCOMING' ? 'not-allowed' : 'pointer',
                                            }}
                                        >
                                            <Link
                                                href={
                                                    process.env.NEXT_PUBLIC_PRACTICE_FACILITY_CAMERA_1
                                                        ? process.env.NEXT_PUBLIC_PRACTICE_FACILITY_CAMERA_1
                                                        : '/'
                                                }
                                                target="_blank"
                                                sx={{
                                                    pointerEvents: status === 'UPCOMING' ? 'none' : 'auto',
                                                }}
                                            >
                                                Camera 1
                                            </Link>
                                            ,
                                            <Link
                                                sx={{
                                                    marginLeft: 0.5,
                                                    pointerEvents: status === 'UPCOMING' ? 'none' : 'auto',
                                                }}
                                                href={
                                                    process.env.NEXT_PUBLIC_PRACTICE_FACILITY_CAMERA_2
                                                        ? process.env.NEXT_PUBLIC_PRACTICE_FACILITY_CAMERA_2
                                                        : '/'
                                                }
                                                target="_blank"
                                            >
                                                Camera 2
                                            </Link>
                                        </Box>
                                        {status === 'UPCOMING' && (
                                            <Tooltip title="Links available at start of workout">
                                                <IconButton size="small">
                                                    <InfoIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Stack>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <SectionHeader label="Participants" />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                rowGap: 1,
                                columnGap: 2,
                            }}
                        >
                            {participants
                                .sort((a, b) => ascending(a.lastName, b.lastName))
                                .map((p) => (
                                    <WorkoutParticipant
                                        key={p.playerId}
                                        participant={p}
                                        workoutDate={date}
                                        abbreviate
                                        width={196}
                                    />
                                ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {notes && <FormattedText label="Workout Notes" text={notes} />}
                        {!notes && (
                            <Box
                                sx={{
                                    fontSize: '14px',
                                    color: 'text.secondary',
                                    fontStyle: 'italic',
                                }}
                            >
                                No Notes Found
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </UncollapsedCardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 0,

                        '&.MuiCardContent-root:last-child': { paddingBottom: 0 },
                    }}
                >
                    <Stack spacing={2}>
                        {participants.map((p) => {
                            const { measurements } = p
                            return (
                                <React.Fragment key={p.playerId}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'space-between',
                                                flexDirection: isMobile ? 'column' : 'row',
                                                gap: isMobile ? 2 : 0,
                                            }}
                                        >
                                            <WorkoutParticipant
                                                participant={p}
                                                workoutDate={date}
                                                valueFontSize={isMobile ? 14 : 16}
                                                labelFontSize={isMobile ? 12 : 14}
                                                abbreviate={!!isMobile}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-end',
                                                    gap: 1,
                                                }}
                                            >
                                                <SummaryStat
                                                    label="Knicks 80"
                                                    value={asNumber(p.knicks80) || '--'}
                                                    isLoading={false}
                                                    height={25}
                                                    width={115}
                                                    fontSize={18}
                                                />
                                                <SummaryStat
                                                    label="Knicks 103"
                                                    value={asNumber(p.knicks103) || '--'}
                                                    isLoading={false}
                                                    height={25}
                                                    width={115}
                                                    fontSize={18}
                                                />
                                            </Box>
                                        </Box>
                                        <PermissionContent type="measurements">
                                            <Box>
                                                <ParticipantMeasurments
                                                    measurements={measurements}
                                                    draftYear={dayjs(date).year()}
                                                    secondaryPosition={p.secondaryPosition}
                                                />
                                            </Box>
                                        </PermissionContent>
                                        <Box>
                                            {p.notes && <FormattedText label={null} text={p.notes} />}
                                            {!p.notes && (
                                                <Box
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: 'text.secondary',
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    {' '}
                                                    No Notes Found{' '}
                                                </Box>
                                            )}
                                        </Box>
                                        <Divider />
                                    </Box>
                                </React.Fragment>
                            )
                        })}
                    </Stack>
                </CardContent>
            </Collapse>
            <CardActions
                post={post}
                expanded={expanded}
                setExpanded={setExpanded}
                isMobile={isMobile}
                hasCollapsedContent={hasOverflowContent || !!participants.length}
            />
            {commentSection}
        </Card>
    )
}

export default WorkoutPost
