import React from 'react'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import StickyNoteIcon from '@mui/icons-material/StickyNote2'

type PlayerListProps = {
    name: Enum.PlayerListName
}

const PlayerList = ({ name }: PlayerListProps): JSX.Element => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
        }}
    >
        <Chip
            sx={{ paddingX: 0.5 }}
            size="small"
            icon={<StickyNoteIcon sx={{ width: 16, height: 16 }} />}
            label={name}
            variant="outlined"
        />
    </Box>
)

export default PlayerList
