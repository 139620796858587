import type { IconButtonProps } from '@mui/material/IconButton'
import React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type ExpandMoreProps = IconButtonProps & {
    expand: boolean
}

const ExpandMore = styled(({ expand, ...rest }: ExpandMoreProps) => (
    <IconButton {...rest}>
        <ExpandMoreIcon />
    </IconButton>
))(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default ExpandMore
