import React from 'react'
import Typography from '@mui/material/Typography'

type PageNameProps = {
    text: string
}

const PageName = ({ text }: PageNameProps): JSX.Element => (
    <Typography gutterBottom variant="h6">
        {text}
    </Typography>
)

export default PageName
