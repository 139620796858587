import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import React from 'react'
import Filter1Icon from '@mui/icons-material/Filter1'
import Filter2Icon from '@mui/icons-material/Filter2'
import Filter3Icon from '@mui/icons-material/Filter3'
import FilterNoneIcon from '@mui/icons-material/FilterNone'

type PlayerTierProps = {
    tier: Enum.PlayerTier
}

export const getTierName = (
    tier: Enum.PlayerTier
): { tierName: string | null; tierIcon: React.ReactElement | null } => {
    switch (tier) {
        case 'PRIORITY':
            return { tierName: 'Priority', tierIcon: <Filter1Icon sx={{ width: 16, height: 16 }} /> }
        case 'SECONDARY':
            return { tierName: 'Secondary', tierIcon: <Filter2Icon sx={{ width: 16, height: 16 }} /> }
        case 'WATCH_LIST':
            return { tierName: 'Watch List', tierIcon: <Filter3Icon sx={{ width: 16, height: 16 }} /> }
        case 'NO_TIER':
            return { tierName: 'No Tier', tierIcon: <FilterNoneIcon sx={{ width: 16, height: 16 }} /> }
        default:
            return { tierName: null, tierIcon: null }
    }
}

const PlayerTier = ({ tier }: PlayerTierProps): JSX.Element => {
    const { tierName, tierIcon } = getTierName(tier)
    if (!!tierName && !!tierIcon) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Chip sx={{ paddingX: 0.5 }} size="small" icon={tierIcon} label={tierName} variant="outlined" />
            </Box>
        )
    }
    return <>{}</>
}

export default PlayerTier
