import React from 'react'
import MuiCardActions from '@mui/material/CardActions'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CommentIcon from '@mui/icons-material/Comment'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Box from '@mui/material/Box'
import ExpandMore from '../ExpandMore'
import CopyLink from '../CopyLink'
import Audience from './Audience'
import Author from './Author'
import DateTime from './DateTime'

type CardActionsProps = {
    post: DTO.Post
    expanded: boolean
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>
    isMobile: boolean
    hasCollapsedContent?: boolean
    isWidget?: boolean
}

const scrollToView = (event: React.SyntheticEvent<HTMLButtonElement>, expanded: boolean, postID: string): void => {
    const anchor = (event.target as HTMLButtonElement).closest(`#card-element-${postID}`)
    if (anchor && expanded) {
        const entityHeader = document.querySelector('.entity-header')
        let headerHeight = 0
        if (entityHeader !== null) headerHeight = entityHeader.getBoundingClientRect().height
        const subNavHeight = 48

        const appBarHeight = 48

        const topOffset = appBarHeight + subNavHeight + headerHeight

        // only scroll if post is above viewport
        const rect = anchor.getBoundingClientRect()
        const isInViewport = rect.top >= headerHeight + appBarHeight
        if (!isInViewport) {
            anchor.scrollIntoView(true)
            document.documentElement.scrollTop -= topOffset + 5
        }
    }
}

const CardActions = ({
    post,
    expanded,
    setExpanded,
    isMobile,
    hasCollapsedContent,
    isWidget = false,
}: CardActionsProps): JSX.Element => (
    <MuiCardActions sx={{ justifyContent: 'space-between', alignItems: 'center', paddingX: 2 }}>
        <Stack direction="row" alignItems="baseline" spacing={2}>
            <Author
                isMobile={isMobile}
                name={post.author.name}
                nameAbbr={post.author.nameAbbr}
                urlSlug={post.author.urlSlug}
            />
            <DateTime isMobile={isMobile} postedAt={post.postedAt || post.createdAt} />
            {!!post.audience.length && <Audience audience={post.audience} />}
            {!isWidget && (
                <Box sx={{ position: 'relative', bottom: 1 }}>
                    <CopyLink text={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/posts/${post.postID}`} />
                </Box>
            )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
            {post.status === 'ACTIVE' && (
                <>
                    {!!post.attachments.length && (
                        <AttachFileIcon sx={{ color: 'text.secondary', marginRight: { xs: 0, sm: 0.5 } }} />
                    )}
                    <CommentIcon sx={{ color: post.comments.length > 0 ? 'info.main' : 'text.secondary' }} />
                    <Typography variant="caption" fontSize={14} color="text.secondary" fontWeight="medium">
                        {post.comments.length} {isMobile ? '' : post.comments.length === 1 ? 'Comment' : 'Comments'}
                    </Typography>
                </>
            )}
            <ExpandMore
                sx={{
                    color: hasCollapsedContent && !expanded ? 'info.main' : undefined,
                }}
                expand={expanded}
                onClick={(event) => {
                    scrollToView(event, expanded, post.postID)
                    setExpanded(!expanded)
                }}
                aria-expanded={expanded}
                aria-label="show more"
                disabled={isWidget ? !(hasCollapsedContent || expanded) : false}
            />
        </Stack>
    </MuiCardActions>
)

export default CardActions
