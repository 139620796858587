import React from 'react'
import Typography from '@mui/material/Typography'
import Link from '../Link'

type AuthorProps = { isMobile: boolean; name: string; nameAbbr: string; urlSlug: string }

const Author = ({ isMobile, name, nameAbbr, urlSlug }: AuthorProps): JSX.Element => (
    <Link href={`/users/${urlSlug}/feed`}>
        <Typography sx={{ fontSize: '14px' }}>{isMobile ? nameAbbr : name}</Typography>
    </Link>
)

export default Author
