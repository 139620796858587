import Box from '@mui/material/Box'
import React from 'react'
import { useSession } from 'next-auth/react'
import Typography from '@mui/material/Typography'
import PlayerHeadshotAvatar from '@/components/boards/PositionalBoard/PlayerHeadshotAvatar'
import { formatNumber } from '@/lib/utils/math'
import Link from '@/components/Link'
import { firstInitialLastName } from '@/lib/utils/formatters'

type WorkoutParticipantProps = {
    participant: DTO.WorkoutParticipant
    workoutDate: string
    valueFontSize?: number
    labelFontSize?: number
    abbreviate?: boolean
    width?: number
}

const WorkoutParticipant = ({
    participant,
    workoutDate,
    valueFontSize = 14,
    labelFontSize = 12,
    abbreviate = false,
    width,
}: WorkoutParticipantProps): JSX.Element => {
    const { data: session } = useSession()
    const { firstName, lastName, imageUrl, currentAge, position, secondaryPosition, jerseyNum } = participant
    const name = firstName ? `${firstName} ${lastName}` : `${lastName}`
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: width,
                maxWidth: width,
                gap: 1,
                lineHeight: `${valueFontSize + 4}px`,
            }}
        >
            <PlayerHeadshotAvatar
                outlined
                imageUrl={imageUrl}
                height={valueFontSize * 2 + 7}
                width={valueFontSize * 2 + 7}
                toolTip={name}
                teamImageUrl={participant.teamLogoUrl}
            />
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'baseline', gap: 0.5 }}>
                    <Link
                        href={
                            session?.roles.featurePermissions['draft-profile']
                                ? `/players/${participant.urlSlug}/workout-profile?year=${new Date(
                                      workoutDate
                                  ).getFullYear()}`
                                : `/players/${participant.urlSlug}/feed`
                        }
                    >
                        <Typography
                            variant="subtitle2"
                            fontSize={valueFontSize}
                            lineHeight={`${valueFontSize + 4}px`}
                            maxWidth={width ? 130 : undefined}
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            {abbreviate ? firstInitialLastName(name) : name}
                        </Typography>
                    </Link>
                    <Typography
                        variant="body1"
                        fontWeight="medium"
                        lineHeight={`${valueFontSize + 4}px`}
                        fontSize={valueFontSize}
                    >
                        {jerseyNum ? `#${jerseyNum}` : ''}
                    </Typography>
                </Box>
                <Box display="flex" gap="4px" alignItems="baseline">
                    <Typography
                        variant="body1"
                        fontWeight="medium"
                        fontSize={valueFontSize}
                        lineHeight={`${valueFontSize + 4}px`}
                    >
                        {formatNumber(currentAge) || ''}{' '}
                        <Typography
                            variant="overline"
                            color="text.secondary"
                            fontSize={labelFontSize}
                            lineHeight={`${valueFontSize + 4}px`}
                        >
                            AGE |
                        </Typography>
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="medium"
                        fontSize={valueFontSize}
                        lineHeight={`${valueFontSize + 4}px`}
                    >
                        {position || ''}
                        {position && (
                            <Typography
                                component="span"
                                color="text.secondary"
                                fontSize={labelFontSize}
                                lineHeight={`${valueFontSize + 4}px`}
                            >
                                /
                            </Typography>
                        )}
                        {secondaryPosition}{' '}
                        <Typography
                            variant="overline"
                            color="text.secondary"
                            fontSize={labelFontSize}
                            lineHeight={`${valueFontSize + 4}px`}
                        >
                            POS
                        </Typography>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default WorkoutParticipant
