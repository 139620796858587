import type { PostProps } from '../Post'
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import { teal } from '@mui/material/colors'
import { nest } from 'd3-collection'
import IconButton from '@mui/material/IconButton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { AvatarGroup, Button } from '@mui/material'
import FormattedText from '../FormattedText'
import { useBreakPoints } from '../../../lib/hooks'
import { handleTouchTap } from '../../form/formUtils'
import UncollapsedCardContent from '../UncollapsedCardContent'
import CardActions from '../CardActions'
import PostTags, { sortTagsByTypeAndName } from '../PostTags'
import Link from '../../Link'
import { PostHeadshot } from '../PostHeadshot'
import PinPostIcon from '../PinPostIcon'
import Source from './Source'

type IntelPostProps = PostProps<DTO.IntelReport> & { isWidget?: boolean }

const sortKeys = (d: string) => {
    if (d.indexOf('Off-Court (Personal)') > -1) {
        return 0
    }
    if (d.indexOf('Off-Court (Relationships)') > -1) {
        return 5
    }
    if (d === 'On-Court') {
        return 10
    }
    if (d === 'Medical/Physical') {
        return 20
    }
    return 30
}

const sortValues = (a: DTO.TextGrade, b: DTO.TextGrade) =>
    (a.grade === 'RED' ? 0 : 1) - (b.grade === 'RED' ? 0 : 1) || (a.sortOrder as number) - (b.sortOrder as number)

const IntelPost = ({
    post,
    editPostSection,
    commentSection,
    expanded,
    setExpanded,
    mobileTagLimit,
    tabletTagLimit,
    desktopTagLimit,
    maxCardContentHeight,
    canEditPost,
    isWidget = false,
    isPinned = false,
}: IntelPostProps): JSX.Element => {
    const { isMobile, isTablet } = useBreakPoints()
    const [hasOverflowContent, setHasOverflowContent] = useState(false)

    const {
        tags,
        subject,
        type,
        level,
        sourcePerson,
        sourcePersonDescription,
        sourceOrganization,
        sourceOrganizationDescription,
        settingMedium,
        settingDirection,
        reliabilitySources,
        reliabilityInformation,
        textGrades,
        postID,
    } = post

    const postTextTypes = nest<DTO.TextGrade, DTO.TextGrade>()
        .key((d: DTO.TextGrade) => d.category)
        .sortKeys((a, b) => sortKeys(a) - sortKeys(b))
        .sortValues((a, b) => sortValues(a, b))
        .entries(textGrades.filter(({ text }) => !!text))

    return (
        <Card
            id={`card-element-${post.postID}`}
            sx={{
                pageBreakInside: expanded ? 'auto' : 'avoid',
                minWidth: isWidget ? '100%' : undefined,
            }}
            variant={isWidget ? undefined : 'outlined'}
            elevation={0}
        >
            {!isWidget && (
                <CardHeader
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 0,
                        '.MuiCardHeader-avatar': { xs: { marginRight: 0 }, sm: { marginRight: '16px' } },
                    }}
                    avatar={
                        <Stack direction="row" spacing={1}>
                            <Chip
                                size="small"
                                label={isMobile ? '' : `${level} ${type.toUpperCase()}`}
                                sx={{
                                    color: 'common.white',
                                    backgroundColor: teal[400],
                                    fontWeight: 'medium',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    '.MuiChip-label': { xs: { paddingLeft: 0 }, sm: { paddingLeft: '8px' } },
                                }}
                                avatar={<PhoneInTalkIcon style={{ color: 'white' }} />}
                            />
                            <Stack direction="row" spacing={1}>
                                <PostTags
                                    tags={tags}
                                    startIndex={0}
                                    endIndex={isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit}
                                    expanded={expanded}
                                    abbreviate={isMobile || isTablet || isWidget}
                                />
                            </Stack>
                        </Stack>
                    }
                    action={canEditPost && editPostSection}
                    subheader={isPinned && <PinPostIcon />}
                />
            )}
            {expanded && !isWidget && (
                <Stack direction="row" sx={{ paddingX: 2, position: 'relative', flexWrap: 'wrap', gap: 1 }}>
                    <PostTags
                        tags={tags}
                        startIndex={isMobile ? mobileTagLimit : desktopTagLimit}
                        endIndex={tags.length}
                        expanded={expanded}
                        abbreviate={isMobile || isTablet}
                    />
                </Stack>
            )}
            {!isWidget && <Divider sx={{ paddingTop: 1 }} />}
            <UncollapsedCardContent
                maxCardContentHeight={maxCardContentHeight}
                expanded={expanded}
                hasOverflowContent={hasOverflowContent}
                setHasOverflowContent={setHasOverflowContent}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 0.5,
                        marginBottom: 1,
                    }}
                >
                    {!isWidget && (
                        <Box sx={{ width: '100%' }}>
                            {tags.some((d) => 'playerHeadshot' in d && d.playerHeadshot) && (
                                <Box sx={{ float: 'left', display: 'inline-block', marginRight: 2 }}>
                                    <AvatarGroup
                                        max={
                                            (isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit) +
                                            1
                                        }
                                        classes={{ avatar: 'plusAvatar' }}
                                        sx={{
                                            'span.MuiBadge-badge > .MuiAvatar-root': {
                                                border: 'none !important',
                                                backgroundColor: 'none !important',
                                            },
                                            '.MuiAvatar-root': {
                                                border: '1px solid rgba(0,0,0,.1)',
                                            },
                                            '.plusAvatar': {
                                                border: '1px solid rgba(0,0,0,.1)',
                                                fontSize: '14px',
                                                color: 'text.secondary',
                                                backgroundColor: 'white !important',
                                                height: '35px',
                                                width: '35px',
                                            },
                                        }}
                                    >
                                        {sortTagsByTypeAndName(tags).map(
                                            (d) =>
                                                'playerHeadshot' in d &&
                                                d.playerHeadshot && (
                                                    <PostHeadshot key={d.entityId} tag={d} marginRight={0} />
                                                )
                                        )}
                                    </AvatarGroup>
                                </Box>
                            )}
                            <Typography
                                variant="h6"
                                fontSize={isMobile ? '18px' : '20px'}
                                lineHeight={isMobile ? 1.4 : 'initial'}
                                sx={{ marginTop: '5px' }}
                            >
                                {subject}
                            </Typography>
                        </Box>
                    )}
                    {isWidget && (
                        <Box display="flex" width="100%">
                            <Box display="flex" flexDirection="row">
                                <Typography variant="subtitle2" fontSize="18px" color="black">
                                    {subject}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row-reverse" flexGrow={1}>
                                <Link href={`/posts/${postID}`} target="_blank">
                                    <Button
                                        variant="text"
                                        endIcon={<OpenInNewIcon />}
                                        size="small"
                                        sx={{
                                            lineHeight: 1.5,
                                            display: { xs: 'none', lg: 'flex' },
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        Post
                                    </Button>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        sx={{ lineHeight: 1.5, display: { xs: 'flex', lg: 'none' } }}
                                        onClick={() => {}}
                                    >
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Link>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box onTouchEnd={(e) => handleTouchTap(e, setExpanded, expanded)} sx={{ clear: 'both' }}>
                    <Box marginBottom={2}>
                        <Source
                            sourcePerson={sourcePerson}
                            sourcePersonDescription={sourcePersonDescription}
                            sourceOrganizationDescription={sourceOrganizationDescription}
                            sourceOrganization={sourceOrganization}
                            settingDirection={settingDirection}
                            settingMedium={settingMedium}
                            reliabilitySources={reliabilitySources}
                            reliabilityInformation={reliabilityInformation}
                            isWidget={isWidget}
                        />
                    </Box>
                    {postTextTypes.length > 0 && (
                        <FormattedText
                            label={isWidget ? '' : postTextTypes[0].key}
                            text=""
                            intensityGrade={null}
                            subCategories={postTextTypes[0].values as DTO.TextGrade[]}
                            marginBottom={!!isWidget}
                        />
                    )}
                </Box>
            </UncollapsedCardContent>
            {!isWidget && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 0,

                            '&.MuiCardContent-root:last-child': { paddingBottom: 0 },
                        }}
                    >
                        {postTextTypes.length > 1 &&
                            postTextTypes
                                .slice(1)
                                .map((x) => (
                                    <FormattedText
                                        key={x.key}
                                        label={x.key}
                                        intensityGrade={null}
                                        subCategories={x.values as DTO.TextGrade[]}
                                        marginBottom
                                    />
                                ))}
                    </CardContent>
                </Collapse>
            )}
            <CardActions
                post={post}
                expanded={expanded}
                setExpanded={setExpanded}
                isMobile={isMobile || isWidget}
                hasCollapsedContent={hasOverflowContent || (textGrades.length > 1 && !isWidget)}
                isWidget={isWidget}
            />
            {commentSection}
        </Card>
    )
}

export default IntelPost
