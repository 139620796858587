import React from 'react'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const PostSkeleton = (): JSX.Element => (
    <Card variant="outlined">
        <CardHeader
            sx={{
                paddingTop: 1,
                paddingBottom: 0,
            }}
            avatar={
                <Stack direction="row" spacing={1}>
                    <Chip size="small" label={<Skeleton sx={{ width: { xs: 12, sm: 100 } }} />} />
                    <Chip size="small" label={<Skeleton sx={{ width: { xs: 60, sm: 100 } }} />} variant="outlined" />
                </Stack>
            }
        />
        <Divider sx={{ paddingTop: 1 }} />
        <CardContent sx={{ paddingTop: 1.5, paddingBottom: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flexStart',
                    gap: 0.5,
                    marginBottom: 1,
                }}
            >
                <Typography variant="h6">
                    <Skeleton width={130} />
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        gap: 0.5,
                        marginLeft: 1,
                        position: 'relative',
                        top: 8,
                    }}
                >
                    <Skeleton height={14} width={14} sx={{ position: 'relative', top: '3px' }} variant="circular" />
                    <Stack direction="row" spacing={1}>
                        <Typography variant="body2" fontWeight="medium">
                            <Skeleton width={35} />
                        </Typography>
                        <Typography variant="body2">
                            <Skeleton width={60} />
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton sx={{ display: { xs: 'block', sm: 'none' } }} />
            <Skeleton sx={{ display: { xs: 'block', sm: 'none' } }} />
        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between', paddingX: 2 }}>
            <Stack direction="row" alignItems="baseline" spacing={2}>
                <Skeleton sx={{ width: { xs: 60, lg: 80 } }} />
                <Skeleton sx={{ width: { xs: 60, lg: 100 } }} />
                <Skeleton height={18} width={18} sx={{ position: 'relative', top: '4px' }} variant="circular" />
            </Stack>
            <ExpandMoreIcon color="disabled" />
        </CardActions>
    </Card>
)

export default PostSkeleton
