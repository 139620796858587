import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { descending } from 'd3-array'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import PlayerHeadshotAvatar from '@/components/boards/PositionalBoard/PlayerHeadshotAvatar'

type GameLeaderProps = { player: DTO.GameBoxscore | undefined }

type GameLeaderSectionProps = {
    isBoxscoreLoading: boolean
    players: DTO.GameBoxscore[]
}

const StatSkeleton = () => <Skeleton width={10} sx={{ display: 'inline-block' }} />
const GameLeader = ({ player }: GameLeaderProps) => (
    <Box display="flex" alignItems="center" gap={1} minWidth={180}>
        <Box>
            <PlayerHeadshotAvatar imageUrl={player?.playerHeadshotUrl} outlined />
        </Box>
        <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" fontSize="14px" lineHeight={1.2}>
                {player ? player.playerName : <Skeleton />}
            </Typography>
            <Box display="flex" gap="4px" alignItems="baseline">
                <Typography variant="body1" fontWeight="medium" fontSize="14px" lineHeight={1.2}>
                    {player ? player.pts : <StatSkeleton />}{' '}
                    <Typography variant="overline" color="text.secondary" lineHeight={1.2}>
                        PTS,
                    </Typography>
                </Typography>
                <Typography variant="body1" fontWeight="medium" fontSize="14px" lineHeight={1.2}>
                    {player ? player.trb : <StatSkeleton />}{' '}
                    <Typography variant="overline" color="text.secondary" lineHeight={1.2}>
                        REB,
                    </Typography>
                </Typography>
                <Typography variant="body1" fontWeight="medium" fontSize="14px" lineHeight={1.2}>
                    {player ? player.ast : <StatSkeleton />}{' '}
                    <Typography variant="overline" color="text.secondary" lineHeight={1.2}>
                        AST
                    </Typography>
                </Typography>
            </Box>
        </Box>
    </Box>
)

const GameLeaderSection = ({ players, isBoxscoreLoading }: GameLeaderSectionProps): JSX.Element => {
    const playerSortedByPts = useMemo<DTO.GameBoxscore[] | undefined[]>(
        () =>
            isBoxscoreLoading
                ? new Array<undefined>(4).fill(undefined)
                : players.sort((a, b) => descending(a.pts || 0, b.pts || 0)).slice(0, 4),
        [players, isBoxscoreLoading]
    )

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle2" fontSize="16px" gutterBottom>
                    Game Leaders
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                {playerSortedByPts.map((player, idx) => (
                    <Grid item marginBottom={1} key={player ? player.playerId : idx}>
                        <GameLeader player={player} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default GameLeaderSection
