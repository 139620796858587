import React from 'react'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import GroupsIcon from '@mui/icons-material/Groups'
import Box from '@mui/material/Box'
import ImageWithFallback from '../ImageWithFallback'

type PostTagsProps = {
    tags: DTO.PostTag[]
    startIndex: number
    endIndex: number
    expanded: boolean
    abbreviate: boolean
}

type TagGroups = {
    player: DTO.PostTag[]
    team: DTO.PostTag[]
    agent: DTO.PostTag[]
    staff: DTO.PostTag[]
    other: DTO.PostTag[]
}

const sortTagsByNameComparator = (a: DTO.PostTag, b: DTO.PostTag) => a.name.localeCompare(b.name)

const sortTagsByLastNameFirstNameComparator = (a: DTO.PostTag, b: DTO.PostTag) => {
    const [firstNameA, ...nameA] = a.name.split(' ')
    const [firstNameB, ...nameB] = b.name.split(' ')
    const lastNameA = nameA.join(' ')
    const lastNameB = nameB.join(' ')
    return lastNameA.localeCompare(lastNameB) || firstNameA.localeCompare(firstNameB)
}

const groupTagsByType = (postTags: DTO.PostTag[]): TagGroups => {
    const result = postTags.reduce(
        (acc: TagGroups, curr) => {
            acc[curr.entityType].push(curr)
            return acc
        },
        { player: [], team: [], agent: [], staff: [], other: [] }
    )
    result.player.sort((a: DTO.PostTag, b: DTO.PostTag) => sortTagsByLastNameFirstNameComparator(a, b))
    result.team.sort((a: DTO.PostTag, b: DTO.PostTag) => sortTagsByNameComparator(a, b))
    result.agent.sort((a: DTO.PostTag, b: DTO.PostTag) => sortTagsByLastNameFirstNameComparator(a, b))
    result.staff.sort((a: DTO.PostTag, b: DTO.PostTag) => sortTagsByLastNameFirstNameComparator(a, b))
    result.other.sort((a: DTO.PostTag, b: DTO.PostTag) => sortTagsByNameComparator(a, b))
    return result
}

const unGroupTagsByType = (postTagGroups: TagGroups): DTO.PostTag[] =>
    Object.values(postTagGroups).flatMap((group) => group.map((tag) => tag))

export const sortTagsByTypeAndName = (tags: DTO.PostTag[]): DTO.PostTag[] => unGroupTagsByType(groupTagsByType(tags))

const handleClick = (entityType: DTO.PostTag['entityType'], entitySlug: DTO.PostTag['entitySlug']) => {
    if (entityType === 'staff') return `/${entityType}/${entitySlug}/feed`
    return `/${entityType}s/${entitySlug}/feed`
}

const PostTags = ({ tags, startIndex, endIndex, expanded, abbreviate }: PostTagsProps): JSX.Element => {
    const renderedTags = sortTagsByTypeAndName(tags).slice(startIndex, endIndex)
    const chips = renderedTags.map((tag) => {
        const { name, abbr, entityType, entitySlug } = tag
        const teamAbbr = 'teamAbbr' in tag ? tag.teamAbbr : undefined
        const teamLogoUrl = 'teamLogoUrl' in tag ? tag.teamLogoUrl : undefined
        return (
            <Chip
                key={entitySlug}
                size="small"
                label={abbreviate ? `${teamAbbr ? `${teamAbbr} \u2022 ` : ''}${abbr}` : name}
                variant="outlined"
                component="a"
                href={handleClick(entityType, entitySlug)}
                clickable
                color="info"
                icon={
                    teamLogoUrl && !abbreviate ? (
                        <Avatar
                            sx={{
                                width: '22px',
                                height: '22px',
                                backgroundColor: 'common.white',
                            }}
                        >
                            <ImageWithFallback alt={abbr || ''} src={teamLogoUrl || ''} fallbackSize={22}>
                                <GroupsIcon />
                            </ImageWithFallback>
                        </Avatar>
                    ) : (
                        <Box />
                    )
                }
                sx={{ '.MuiChip-label': { maxWidth: { xs: 135, md: 'initial' } } }}
            />
        )
    })

    if (!expanded && tags.length > endIndex) {
        const moreLabel = `+${tags.length - endIndex}`
        chips.push(<Chip key={moreLabel} size="small" label={moreLabel} variant="outlined" />)
    }
    return <>{chips}</>
}

export default PostTags
