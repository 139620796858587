import type { PostProps } from '../Post'
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import SportsIcon from '@mui/icons-material/Sports'
import { useBreakPoints } from '../../../lib/hooks'
import { handleTouchTap } from '../../form/formUtils'
import FormattedText from '../FormattedText'
import UncollapsedCardContent from '../UncollapsedCardContent'
import CardActions from '../CardActions'
import PostTags from '../PostTags'
import { PostHeadshot } from '../PostHeadshot'
import PinPostIcon from '../PinPostIcon'

const PlayerDevelopmentPost = ({
    post,
    editPostSection,
    commentSection,
    expanded,
    setExpanded,
    mobileTagLimit,
    tabletTagLimit,
    desktopTagLimit,
    maxCardContentHeight,
    canEditPost,
    isPinned = false,
}: PostProps<DTO.PlayerDevelopmentReport>): JSX.Element => {
    const [hasOverflowContent, setHasOverflowContent] = useState(false)
    const { isMobile, isTablet } = useBreakPoints()
    const { tags, subject, coaching, physical, playerDevelopmentTagName } = post

    const postTextTypes = [
        { label: 'Coaching', text: coaching },
        { label: 'Physical', text: physical },
    ].filter(({ text }) => !!text)

    return (
        <Card
            sx={{ pageBreakInside: expanded ? 'auto' : 'avoid' }}
            id={`card-element-${post.postID}`}
            variant="outlined"
        >
            <CardHeader
                sx={{
                    paddingTop: 1,
                    paddingBottom: 0,
                    '.MuiCardHeader-avatar': { xs: { marginRight: 0 }, sm: { marginRight: '16px' } },
                }}
                avatar={
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            label={isMobile ? '' : `${'PLAYER DEV'}`}
                            sx={{
                                color: 'common.white',
                                backgroundColor: 'post.playerDevelopment',
                                fontWeight: 'medium',
                                display: 'flex',
                                justifyContent: 'center',
                                '.MuiChip-label': { xs: { paddingLeft: 0 }, sm: { paddingLeft: '8px' } },
                            }}
                            avatar={<SportsIcon style={{ position: 'relative', color: 'white' }} />}
                        />

                        <Stack direction="row" spacing={1}>
                            <PostTags
                                tags={tags}
                                startIndex={0}
                                endIndex={isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit}
                                expanded={expanded}
                                abbreviate={isMobile || isTablet}
                            />
                            <Chip size="small" color="default" variant="outlined" label={playerDevelopmentTagName} />
                        </Stack>
                    </Stack>
                }
                action={canEditPost && editPostSection}
                subheader={isPinned && <PinPostIcon />}
            />
            <Divider sx={{ paddingTop: 1 }} />
            <UncollapsedCardContent
                maxCardContentHeight={maxCardContentHeight}
                expanded={expanded}
                hasOverflowContent={hasOverflowContent}
                setHasOverflowContent={setHasOverflowContent}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 1,
                        marginBottom: 1,
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <PostHeadshot tag={tags[0] as DTO.PlayerTag} />
                        <Typography
                            variant="h6"
                            fontSize={isMobile ? '18px' : '20px'}
                            lineHeight={isMobile ? 1.4 : 'initial'}
                            sx={{ marginTop: '5px' }}
                        >
                            {subject}
                        </Typography>
                    </Box>
                </Box>
                <Box onTouchEnd={(e) => handleTouchTap(e, setExpanded, expanded)}>
                    <FormattedText
                        label={postTextTypes[0]?.label || ''}
                        text={postTextTypes[0]?.text || ''}
                        marginBottom={false}
                    />
                </Box>
            </UncollapsedCardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 0,

                        '&.MuiCardContent-root:last-child': { paddingBottom: 0 },
                    }}
                >
                    {postTextTypes.length > 1 &&
                        postTextTypes.slice(1).map((x) => (
                            <FormattedText
                                key={x.label}
                                label={x.label}
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                text={x.text!}
                            />
                        ))}
                </CardContent>
            </Collapse>
            <CardActions
                post={post}
                expanded={expanded}
                setExpanded={setExpanded}
                isMobile={isMobile}
                hasCollapsedContent={hasOverflowContent || postTextTypes.length > 1}
            />
            {commentSection}
        </Card>
    )
}

export default PlayerDevelopmentPost
